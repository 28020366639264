import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    email: null,
  },
  reducers: {
    login: (state, action) => {
      // In a real app, you would handle authentication and get the token from the backend
      state.token = 'fake-jwt-token';
      state.email = action.payload.email;
    },
    logout: (state) => {
      state.token = null;
      state.email = null;
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
