import React, { useState } from 'react';
import axios from 'axios';
import { Container, TextField, Button, List, ListItem, ListItemText, CircularProgress, Typography, Box, Card, CardContent } from '@mui/material';
import { useSelector } from 'react-redux';

const SearchComponent = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const userEmail = useSelector((state) => state.auth.email);
  const handleSearch = async () => {
    setLoading(true);
    setNoResults(false);
    setResults([]);
    try {
      const response = await axios.get(`${apiUrl}/proxy/search`, {
          params: {
              query: query,
              email: userEmail
          }
      });
      const data = response.data;
      
      if (data.length === 0) {
          setNoResults(true);
      } else {
          setResults(data);
      }
  } catch (error) {
      console.error('Error fetching search results:', error);
      setNoResults(true);
  } finally {
      setLoading(false);
  }
    
  };

  return (
    <Container maxWidth="sm">
      <TextField
        label="Search Completed Cases"
        variant="outlined"
        fullWidth
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        style={{ marginTop: '20px' }}
      />
      <Box sx={{ position: 'relative', display: 'inline-flex', marginTop: '20px', marginBottom: '20px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          disabled={loading}
          sx={{ width: '200px', height: '36px' }} // Fixed size
        >
          {loading && <CircularProgress size={24} sx={{
            color: 'white',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px'
          }} />}
          {!loading && 'Search'}
        </Button>
      </Box>
      {noResults && !loading && (
        <Typography variant="body1" color="textSecondary">
          No results found.
        </Typography>
      )}
     <List>
  {results.map((result, index) => (
    <Card key={index} variant="outlined" sx={{ marginBottom: '10px' }}>
      <CardContent>
        <ListItem
          button
          component="a"
          href={`https://mevzuat.mahkemeler.net${result.documentLink}`} // Prepend the base URL
        >
          <ListItemText primary={result.content} />
        </ListItem>
      </CardContent>
    </Card>
  ))}
</List>

    </Container>
  );
};

export default SearchComponent;
