import React, { useState } from 'react';
import { Container, TextField, Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { login } from '../authSlice';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${apiUrl}/Account/login`, {
        email,
        password,
      });
      const token = response.data.token;
      const decodedToken = jwtDecode(token);
      const userEmail = decodedToken.sub;
      console.log(userEmail);
      dispatch(login({ token, email: userEmail }));
      navigate('/search');
    } catch (err) {
      setError('Invalid email or password');
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" sx={{ mt: 4 }}>Login</Typography>
      <TextField
        label="Email"
        type="email"
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        sx={{ mt: 2 }}
      />
      <TextField
        label="Password"
        type="password"
        fullWidth
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={{ mt: 2 }}
      />
      {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
      <Button variant="contained" color="primary" onClick={handleLogin} sx={{ mt: 2 }}>
        Login
      </Button>
      {/* <Button variant="text" onClick={() => navigate('/register')} sx={{ mt: 2 }}>
        Don't have an account? Register here
      </Button> */}
    </Container>
  );
};

export default Login;
